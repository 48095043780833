<template>
    <div id="document" class="mt-4 p-5 position-relative readonly">
      <b-tabs
        class="text-xs border-0"
        active-nav-item-class="bg-facebook font-weight-light nav-link text-white" 
        content-class="mt-3">
        <b-tab title="ใบสั่งซื้อ" title-link-class="border-0 font-prompt-light" active>
          <PurchaseOrder></PurchaseOrder>
        </b-tab>
        <b-tab title="ใบสั่งขาย" title-link-class="border-0 font-prompt-light">
          <SaleOrder></SaleOrder>
        </b-tab>
        <b-tab title="ใบรับสินค้า" title-link-class="border-0 font-prompt-light">
          <GoodsReceipt></GoodsReceipt>
        </b-tab>
        <b-tab title="ใบแจ้งหนี้" title-link-class="border-0 font-prompt-light">
          <Invoice></Invoice>
        </b-tab>
        <b-tab title="ใบเสร็จรับเงิน/ใบกำกับภาษี" title-link-class="border-0 font-prompt-light">
          <TaxInvoice></TaxInvoice>
        </b-tab>
        <b-tab title="ใบคืนสินค้า" title-link-class="border-0 font-prompt-light">
          <Refunds></Refunds>
        </b-tab>
        <b-tab title="ใบเสนอราคา" title-link-class="border-0 font-prompt-light">
          <Quotation></Quotation>
        </b-tab>
        <b-tab title="ใบส่งคืนจากลูกค้า" title-link-class="border-0 font-prompt-light">
          <ReturnCustomer></ReturnCustomer>
        </b-tab>
        <b-tab title="ใบเบิกสินค้า" title-link-class="border-0 font-prompt-light">
          <Requisitions></Requisitions>
        </b-tab>
        <b-tab title="ใบวางบิล" title-link-class="border-0 font-prompt-light">
          <BillInvoice></BillInvoice>
        </b-tab>
        <b-tab title="ใบเสร็จรับเงิน" title-link-class="border-0 font-prompt-light">
          <Receipt></Receipt>
        </b-tab>
        <b-tab title="ใบมัดจำ" title-link-class="border-0 font-prompt-light">
          <Deposit></Deposit>
        </b-tab>
        <b-tab title="ใบสั่งผลิต" title-link-class="border-0 font-prompt-light">
          <Production></Production>
        </b-tab>
        <b-tab title="ใบส่งสินค้า" title-link-class="border-0 font-prompt-light">
          <DeliveryNote></DeliveryNote>
        </b-tab>
        <b-tab title="แบบฟอร์มใบโป้ว" title-link-class="border-0 font-prompt-light">
          <FormPu :data="form_pu" :title="'แบบฟอร์มใบโป้ว'" :source="document_type.PU"></FormPu>
        </b-tab>
        <b-tab title="แบบฟอร์มใบผลิต" title-link-class="border-0 font-prompt-light">
          <FormPu :data="form_po" :title="'แบบฟอร์มใบผลิต'" :source="document_type.PO"></FormPu>
        </b-tab>
        <b-tab title="แบบฟอร์มใบผลิตแผ่น" title-link-class="border-0 font-prompt-light">
          <BranchProductionSheet></BranchProductionSheet>
        </b-tab>
      </b-tabs>
    </div>
  </template>

<script>
import { DOCUMENT_FORM_TYPE } from '../../../mixins/enum'
import PurchaseOrder from '@/components/dialogs/purchase-order'
import SaleOrder from '@/components/dialogs/sale-order'
import GoodsReceipt from '@/components/dialogs/goods-receipt'
import Invoice from '@/components/dialogs/invoice'
import TaxInvoice from '@/components/dialogs/tax-invoice'
import Refunds from '@/components/dialogs/refunds'
import Quotation from '@/components/dialogs/quotation'
import ReturnCustomer from '@/components/dialogs/return-customer'
import Requisitions from '@/components/dialogs/requisitions'
import BillInvoice from '@/components/dialogs/bill-invoice'
import Receipt from '@/components/dialogs/receipt'
import Deposit from '@/components/dialogs/deposit'
import Production from '@/components/dialogs/production'
import FormPu from '@/components/dialogs/form'
import DeliveryNote from '@/components/dialogs/delivery-note'
import BranchProductionSheet from '@/components/dialogs/branch-production-sheet'

export default {
  name: 'documents',
  components: {
    PurchaseOrder,
    SaleOrder,
    GoodsReceipt,
    Invoice,
    TaxInvoice,
    Refunds,
    Quotation,
    ReturnCustomer,
    Requisitions,
    BillInvoice,
    Receipt,
    Deposit,
    Production,
    FormPu,
    DeliveryNote,
    BranchProductionSheet
  },
  data() {
    return {
      document_type: DOCUMENT_FORM_TYPE,
      form_pu: {
        no: "STKR6601-06",
        doc_date: new Date(),
        branch: 'แสงไทยโคราช',
        branch_pu: 'แสงไทยบางเลน',
        wave: 'ลอนผนัง PN-620',
        roof: '0.35/ลายไม้/เหล็กนอก',
        pu_foam: '',
        facing: '',
        unit_price: 0,
        cutting_buttom_price: 0,
        total_unit_price: 0,
        factorys: [
          {
            id: '1',
            name: 'โรงพิมพ์ประทาย',
            products: [
              {
                id: '1',
                name: 'ลอนผนัง PN-620',
                length: 5.35,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 5.35,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '2',
                name: 'ลอนผนัง PN-620',
                length: 5.23,
                qty: 2,
                except: 'แผ่น',
                note: '',
                total_length: 5.23,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '3',
                name: 'ลอนผนัง PN-620',
                length: 4.95,
                qty: 2,
                except: 'แผ่น',
                note: '',
                total_length: 4.95,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '4',
                name: 'ลอนผนัง PN-620',
                length: 4.70,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 4.70,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '5',
                name: 'ลอนผนัง PN-620',
                length: 4.57,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 4.57,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '6',
                name: 'ลอนผนัง PN-620',
                length: 4.30,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 4.30,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              }
            ]
          },
          {
            id: '2',
            name: 'โรงพิมพ์ประทาย 2',
            products: [
              {
                id: '1',
                name: 'ลอนผนัง PN-620',
                length: 5.35,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 5.35,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '2',
                name: 'ลอนผนัง PN-620',
                length: 5.23,
                qty: 2,
                except: 'แผ่น',
                note: '',
                total_length: 5.23,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '3',
                name: 'ลอนผนัง PN-620',
                length: 4.95,
                qty: 2,
                except: 'แผ่น',
                note: '',
                total_length: 4.95,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '4',
                name: 'ลอนผนัง PN-620',
                length: 4.70,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 4.70,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '5',
                name: 'ลอนผนัง PN-620',
                length: 4.57,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 4.57,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '6',
                name: 'ลอนผนัง PN-620',
                length: 4.30,
                qty: 1,
                except: 'แผ่น',
                note: '',
                total_length: 4.30,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              }
            ]
          }
        ],
        grand_total_m: 39.28,
        grand_total: 0,
        note: 'PU 25 ม.ม. ท้องเหล็ก 0.35/เทาอ่อน Alloy grey/ เหล็กนอก JL (ติดเต็ม)',
        remark: 'ฝากมากับรถเทเลอร์ มาโคราช'
      },
      form_po: {
        no: "STKR6602-04",
        doc_date: new Date(),
        branch: 'แสงไทยโคราช',
        branch_pu: 'แสงไทยอุดร',
        wave: 'ST-25-760',
        roof: '0.35/สีโกรบอลบราวน์/เหล็กนอก JL (ม้วนโคราช)',
        pu_foam: '25 ม.ม.(ติดเต็ม)',
        facing: '0.35/เทาอ่อน Alloy grey/เหล็กนอก JL(ม้วนโคราช)',
        unit_price: 0,
        cutting_buttom_price: 0,
        total_unit_price: 0,
        storage: 'โกดัง 3',
        factorys: [
          {
            id: '1',
            name: 'บจก. ไมนด์เซท',
            products: [
              {
                id: '1',
                name: 'ลอน ST-25-760+PU 25 ม.ม.',
                length: 6.18,
                qty: 51,
                except: 'แผ่น',
                note: '',
                total_length: 315.18,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              },
              {
                id: '2',
                name: 'ลอน ST-25-760+PU 25 ม.ม.',
                length: 6.11,
                qty: 23,
                except: 'แผ่น',
                note: '',
                total_length: 140.53,
                cost_per_m: 0,
                cutting_cost: 0,
                total: 0
              }
            ]
          }
        ],
        grand_total_m: 455.71,
        grand_total: 0,
        note: 'PU 25 ม.ม. ท้องเหล็ก 0.35/เทาอ่อน Alloy grey/ เหล็กนอก JL (ติดเต็ม)',
        remark: 'เวลวรับส่งสินค้า: โคราชรับ วันศุกร์ 24/2/66'
      }
    }
  }
}
</script>

<style>
  #document .nav-tabs {
    border: 0 !important;
  }

  #document .bg-facebook {
    background: #3b579d !important;
  }
</style>