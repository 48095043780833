const STOCKIN_TYPE = {
    RECEIVE: 'stockin-receive',
    COUNT: 'stockin-count',
    QUALITY: 'stockin-quality',
    STORE: 'stockin-store',
    INFO: 'stockin-detail',
    BARCODE: 'stockin-barcode'
}

const STOCK_COINS_STATUS = {
    Insufficient: 0,
    enough: 1,
    recently: 2
}

const DOCUMENT_FORM_TYPE = {
    PU: 1,
    PO: 2
}
export { STOCKIN_TYPE, STOCK_COINS_STATUS, DOCUMENT_FORM_TYPE }