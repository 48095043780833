import 'jspdf-autotable'
import Loading from "@/components/widgets/Loading.vue"

export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      company: {
        company_name: '',
        address: '',
        tax_number: '',
        phone_number: '',
        fax_number: ''
      },
      document: {
        document_no: '',
        created_at: '',
        paid_at: '',
      },
      customer: {
        code: '',
        name: '',
        address: '',
        tax_id: '',
        phone_number: ''
      },
      items: [],
      deposit: 0
    }
  },
  watch: {
    'data'(newValue, oldValue) {
      if (newValue) {
        const company = newValue.company
        if (company) {
          this.company.company_name = company.company_name
          this.company.address = company.address
          this.company.tax_number = company.tax_number
          this.company.phone_number = company.phone_number
          this.company.fax_number = company.fax_number
        }        

        this.document.document_no = newValue.document_no
        this.document.created_at = newValue.created_at
        this.document.paid_at = newValue.paid_at

        const customer = newValue.customers.length > 0 ? newValue.customers[0]: null
        if (customer) {
          this.customer.code = customer.code
          this.customer.name = customer.name
          this.customer.address = customer.address
          this.customer.tax_id = customer.tax_id
          this.customer.phone_number = customer.phone_number
        }

        this.items = newValue.bills
        if (this.items.length < 20) {
          let loopNumber = 20
          loopNumber = loopNumber - this.items.length
          for (let index = 0; index < loopNumber; index++) {
            this.items.push({})
          }
        }
        
        this.deposit = newValue.amount
      }
    }
  },
  methods: {
    async onInitIFrame() {
      this.loading = true
      const result = await this.$axios.get(
        this.$host + "/api/deposit-pdf/"+ this.$route.params?.id,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob"
        }
      )
      const blob = new Blob([result.data], {type: 'application/pdf'});
      const objectUrl = URL.createObjectURL(blob)

      const element = document.getElementById('deposit-sheet')
      element.src = objectUrl

      setTimeout(() => {
        this.loading = false
        this.onIframePrint()
      }, 1000);
    },
    onIframePrint() {
      let iFrame = document.getElementById("deposit-sheet")
      iFrame.focus()
      iFrame.contentWindow.print()
    }
  }
}