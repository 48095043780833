import "jspdf-autotable"
import { jsPDF } from "jspdf"
import Loading from "@/components/widgets/Loading.vue"

export default {
  data: function () {
    return {
      loading: false,
      detail: {
        customer: 'บริษัท ซีอาร์ซี ไทวัสดุ จำกัด',
        no: 'RYKK 6702-0003',
        date: '13/3/2567',
        branch: 'ระยอง',
        color: 'เทาลอฟเกรย์',
        color_thickness: 0.35,
        brand_color: 'M-Pro',
        pooh: '',
        roll: '25-750',
        roll_number: '',
        issuer: '',
        insulation_type: '-',
        facing: '',
        facing_price: 0,
        belly_color: '',
        belly_color_price: 0,
        belly_thickness: '-',
        brand: '',
        brand_price: 0,
        price_per_unit: 0,
        belly_pattern: '-',
        total_price_per_unit: '-',
        curved_astep: '',
        total_length: 114.70,
        total_weight: 258.08,
        pickup_time: 'จัดรถเข้ารับเอง',
        the_poster: '-',
        grand_total: 0
      },
      products: [
        {
          name: 'ครอบจั่วตามแบบรุ่น G610',
          length: 3.10,
          qty: 10,
          except: '',
          note: '',
          total: 31.0,
          cost: 0,
          cut_price: 0,
          multiplier_price: 1,
          nettotal: 0,
          take: ''
        },
        {
          name: 'ครอบข้างตามแบบ G610',
          length: 3.10,
          qty: 17,
          except: '',
          note: '',
          total: 52.70,
          cost: 0,
          cut_price: 0,
          multiplier_price: 1,
          nettotal: 0,
          take: ''
        },
        {
          name: 'ครอบชายแผ่นรุ่น G410',
          length: 3.10,
          qty: 10,
          except: '',
          note: '',
          total: 31.00,
          cost: 0,
          cut_price: 0,
          multiplier_price: 1,
          nettotal: 0,
          take: ''
        },
        {
          name: 'ครอบจั่วตามแบบรุ่น G610',
          length: 3.10,
          qty: 10,
          except: '',
          note: '',
          total: 31.0,
          cost: 0,
          cut_price: 0,
          multiplier_price: 1,
          nettotal: 0,
          take: ''
        },
        {
          name: 'ครอบข้างตามแบบ G610',
          length: 3.10,
          qty: 17,
          except: '',
          note: '',
          total: 52.70,
          cost: 0,
          cut_price: 0,
          multiplier_price: 1,
          nettotal: 0,
          take: ''
        },
        {
          name: 'ครอบชายแผ่นรุ่น G410',
          length: 3.10,
          qty: 10,
          except: '',
          note: '',
          total: 31.00,
          cost: 0,
          cut_price: 0,
          multiplier_price: 1,
          nettotal: 0,
          take: ''
        }
      ]
    }
  },
  components: {
    Loading
  },
  mounted() {
    this.$nextTick(() => {
    })
  },
  methods: {
    async onPdf() {
      this.loading = true
      const result = await this.$axios.get(
        this.$host + "/api/product-sheet",
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob"
        }
      )
      const blob = new Blob([result.data], {type: 'application/pdf'});
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement("a");
      link.href = objectUrl;
      link.setAttribute("download", `QR Stock-${new Date().getTime()}.pdf`);
      document.body.appendChild(link);
      link.click();
      // const element = document.getElementById('branch-sheet-file')
      // element.src = objectUrl

      // setTimeout(() => {
      //   this.loading = false
      //   this.onIframePrint()
      // }, 1000);
    },
    async onInitIFrame() {
      this.loading = true
      setTimeout(async () => {
        const processJspdf = async () => {
          return new Promise(async (resolve, reject) => {
            const doc = new jsPDF("l", "mm", "a4")
            doc.addFileToVFS(
              "THSarabunNew.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunNormal
            );
            // add custom font to file
            doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal")
            doc.setFont("THSarabunNew", "normal")

            doc.addFileToVFS(
              "THSarabunNew Bold.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunBold
            );
            // add custom font to file
            doc.addFont("THSarabunNew Bold.ttf", "THSarabunNew", "bold")
            doc.setFont("THSarabunNew", "bold")

            const head = await this.onInitHeader()
            doc.autoTable({
              theme: "plain",
              margin: { top: 4, bottom: 0, left: 4, right: 4 },
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              showFoot: "lastPage",
              head: head,
              body: []
            })

            const headTable = await this.onInitHeaderTable(doc)
            const body = await this.onInitBody(doc)
            doc.autoTable({
              theme: "plain",
              margin: { top: 4, bottom: 0, left: 4, right: 4 },
              startY: doc.lastAutoTable.finalY + 1,
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              showFoot: "lastPage",
              head: headTable,
              body: body
            })

            const footer = await this.onInitFooter(doc)
            doc.autoTable({
              theme: "plain",
              margin: { top: 4, bottom: 4, left: 4, right: 4 },
              startY: doc.lastAutoTable.finalY + 1,
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              showFoot: "lastPage",
              foot: footer
            })

            resolve(doc)
          })
        }

        const doc = await processJspdf()
        let element = document.getElementById("branch-sheet-file")
        if (element) {
          element.setAttribute("src", doc.output("bloburl"))
          setTimeout(() => {
            this.loading = false;
            this.onIframePrint();
          }, 1000);
        }
      }, 1000)
    },
    onIframePrint() {
      let iFrame = document.getElementById("branch-sheet-file");
      iFrame.focus();
      iFrame.contentWindow.print();
    },
    async onInitData() { },
    onInitHeaderTable() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: "ลำดับ",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 12,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "รายการ",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "ยาว/เมตร",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "right",
                valign: "top"
              }
            },
            {
              content: "จำนวนแผ่น",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "right",
                valign: "top"
              }
            },
            {
              content: "เว้นชาย",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "หมายเหตุ",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 30,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "left",
                valign: "top"
              }
            },
            {
              content: "รวมเมตร",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "right",
                valign: "top"
              }
            },
            {
              content: "ราคาทุน/ม.",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "right",
                valign: "top"
              }
            },
            {
              content: "ค่าตัด",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "right",
                valign: "top"
              }
            },
            {
              content: "ตัวคูณลอนแปลก",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 25,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "right",
                valign: "top"
              }
            },
            {
              content: "รวม",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "right",
                valign: "top"
              }
            },
            {
              content: "Take",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 1,
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fontStyle: "bold",
                halign: "center",
                valign: "top"
              }
            }
          ]
        ]
        resolve(head)
      })
    },
    onInitHeader() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: "แบบฟอร์มใบผลิตสาขา",
              colSpan: 11,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 16,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "?",
              styles: {
                lineWidth: 0,
                cellWidth: 31.75,
                fontSize: 18,
                textColor: [15, 23, 42],
                fillColor: [253, 224, 71],
                cellPadding: {
                  top: 2,
                  bottom: 2
                },
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "ชื่อลูกค้า",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.customer,
              colSpan: 11,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "left",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "NO.",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.no,
              colSpan: 2,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 50,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "วันที่",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.date,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: '',
              colSpan: 7,
              styles: {
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "สาขา",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.branch,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 52.916666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "โป้ว",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.pooh,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: '',
              colSpan: 8,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "สี",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.color,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 52.916666667,
                fontSize: 13,
                textColor: [14, 165, 233],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "ความหนา",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.color_thickness.toString(),
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [14, 165, 233],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "ยี่ห้อ",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.brand_color,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [14, 165, 233],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "ลอน",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.roll,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 52.916666667,
                fontSize: 13,
                textColor: [14, 165, 233],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "เลขที่ม้วน",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.roll_number,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: "ผู้ออกผลิต",
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.issuer,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "ประเภทฉนวน",
              colSpan: 2,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [253, 224, 71],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.detail.insulation_type,
              colSpan: 4,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: 'ราคา/หน่วย',
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 26.458333333,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [252, 165, 165],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: '',
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "ท้อง Facing",
              colSpan: 2,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [253, 224, 71],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.detail.facing,
              colSpan:4,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: this.detail.facing_price.toString(),
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 26.458333333,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [252, 165, 165],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: '',
              colSpan: 5,
              styles: {
                lineWidth: 0,
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "สีท้อง",
              colSpan: 2,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [253, 224, 71],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.detail.belly_color,
              colSpan: 2,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: 'ความหนา',
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "left",
                valign: "middle",
              },
            },
            {
              content: this.detail.belly_thickness,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: this.detail.belly_color_price,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 26.458333333,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [252, 165, 165],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: '',
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 21.166666667,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: 'ท้องเหล็ก/ฟอยลายไม้',
              colSpan: 3,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: 'ราคารวมต่อหน่วย',
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [243, 244, 246],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "ยี่ห้อ",
              colSpan: 2,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [253, 224, 71],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.detail.brand,
              colSpan: 4,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: this.detail.brand_price,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 26.458333333,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [252, 165, 165],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.detail.price_per_unit.toString(),
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 26.458333333,
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [252, 165, 165],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: this.detail.belly_pattern,
              colSpan: 3,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: this.detail.total_price_per_unit,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 20,
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "สเต็ปย้ำโค้ง",
              colSpan: 2,
              styles: {
                lineWidth: 0.2,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                fillColor: [253, 224, 71],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "right",
                valign: "middle",
              },
            },
            {
              content: 'ย้ำหนัก',
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [220, 38, 38],
                cellPadding: 1,
                fontStyle: "bold",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: 'เริ่ม',
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: 'ห่าง',
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: 'ย้ำ',
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
              },
            },
            {
              content: '',
              colSpan: 6,
              styles: {
                lineWidth: 0.5,
                lineColor: [255,255,255],
                cellWidth: 'auto',
                fontSize: 13,
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "center",
                valign: "middle",
              },
            },
          ]
        ]
       
        resolve(head)
      })
    },
    onInitBody() {
      return new Promise(async (resolve, reject) => {
        const data = this.products
        const body = []
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          let obj = {}
          obj = [
            {
              content: (index + 1).toString(),
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "center",
                valign: "top",
              }
            },
            {
              content: element.name,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              }
            },
            {
              content: element.length,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.qty,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.except,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.note,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.total,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.cost,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.cut_price,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.multiplier_price,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.nettotal,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.take,
              styles: {
                cellWidth: 'auto',
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 13,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 1,
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            }
          ]
          body.push(obj)
        }

        let emptyBody = {}
        emptyBody = [
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          }
        ]

        if (body.length <= 8) {
          const number = 8 - body.length;
          for (let index = 0; index < number; index++) {
            body.push(emptyBody)
          }
        }

        const endOfPage = [
          {
            content: "",
            colSpan: 12,
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                top: 0.1
              },
              fontSize: 13,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: 0,
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          }
        ]
        body.push(endOfPage)

        resolve(body)
      })
    },
    onInitFooter(doc) {
      return new Promise((resolve, reject) => {
        const footer = [];
        const grandTotal = [
          {
            content: `ความยาวรวม (M.)`,
            styles: {
              lineWidth: 0,
              cellWidth: 39,
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content: this.detail.total_length,
            styles: {
              lineWidth: 0,
              cellWidth: 39,
              fontSize: 13,
              textColor: [15, 23, 42],
              fillColor: [253, 224, 71],
              cellPadding: 1,
              fontStyle: "normal",
              halign: "right",
              valign: "middle",
            },
          },
          {
            content: `น้ำหนักรวม (KG.)`,
            styles: {
              lineWidth: 0,
              cellWidth: 39,
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content: this.detail.total_weight,
            styles: {
              lineWidth: 0,
              cellWidth: 39,
              fontSize: 13,
              textColor: [15, 23, 42],
              fillColor: [253, 224, 71],
              cellPadding: 1,
              fontStyle: "normal",
              halign: "right",
              valign: "middle",
            },
          },
          {
            content: `ยอดรวมทั้งสิ้น`,
            styles: {
              lineWidth: 0,
              cellWidth: 89.164583333,
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "bold",
              halign: "center",
              valign: "middle",
            },
          },
          {
            content: this.detail.grand_total,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "bold",
              halign: "right",
              valign: "middle",
            },
          },
        ]

        const pickupTime = [
          {
            content: `เวลารับสินค้า`,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              fillColor: [125, 211, 252],
              cellPadding: 1,
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content: this.detail.pickup_time,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [220, 38, 38],
              cellPadding: 1,
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content: `ผู้โป้ว`,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              fillColor: [254, 215, 170],
              cellPadding: 1,
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content: this.detail.the_poster,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ]

        const inspector = [
          {
            content: `ผู้ตรวจสอบ`,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              fillColor: [254, 215, 170],
              cellPadding: {
                left: 1,
                right: 1,
                top: 5,
                bottom: 5
              },
              fontStyle: "bold",
              halign: "left",
              valign: "middle",
            },
          },
          {
            content: `ผู้ออกใบผลิต(ประสานงาน)	`,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "normal",
              halign: "center",
              valign: "bottom",
            },
          },
          {
            content: `ผู้จัดการสาขา`,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "normal",
              halign: "center",
              valign: "bottom",
            },
          },
          {
            content: `ฝ่ายผลิต`,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 13,
              textColor: [15, 23, 42],
              cellPadding: 1,
              fontStyle: "normal",
              halign: "center",
              valign: "bottom",
            },
          },
        ] 

        footer.push(grandTotal)
        footer.push(pickupTime)
        footer.push(inspector)
        resolve(footer);
      });
    },
  }
}
